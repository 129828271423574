export default [
  {
    path: "/operateHome", //运营端首页
    name: "OperateHome",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      deepth: 0.5 // 定义路由的层级
    },
    component: () => import("@pages/operate/home"),
  },

    // *****运营端-供应商模块
  {
    path: "/suppAuditList", //运营端审核列表
    name: "suppAuditList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/supplierAudit/suppAuditList"),
  },
  {
    path: "/suppAuditDetail", //运营端审核详情
    name: "suppAuditDetail",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/supplierAudit/suppAuditDetail"),
  },
  {
    path: "/suppInforImprove", //运营端补充资料
    name: "SuppInforImprove",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/supplierAudit/suppInforImprove"),
  },
    // *****运营端-客户审核模块
  {
    path: "/cusAuditList", //客户审核列表
    name: "CusAuditList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/customerAudit/cusAuditList"),
  },
  {
    path: "/cusAuditDetail", //客户审核详情
    name: "CusAuditDetail",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/customerAudit/cusAuditDetail"),
  },


    // *****运营端-商品审核模块
  {
    path: "/productList", //商品审核列表
    name: "ProductList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/productReview/productList"),
  },
  {
    path: "/productListDetail", //商品审核详情
    name: "ProductListDetail",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/productReview/productListDetail"),
  },




   // *****运营端-创建发货计划模块
  {
    path: "/shippingPlanOperate", //新增发货计划列表
    name: "ShippingPlanOperate",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      keepAlive: true, //需要缓存的页面
      deepth: 1
    },
    component: () => import("@pages/operate/shipPlan/shippingPlan"),
  },
  {
    path: "/creatShippingPlan", //创建发货计划列表
    name: "CreatShippingPlan",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/shipPlan/creatShippingPlan"),
  },
  {
    path: "/createShippingPlanDetailOperate", //创建发货计划详情
    name: "CreateShippingPlanDetailOperate",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      keepAlive: true, //需要缓存的页面
      deepth:2
    },
    component: () => import("@pages/operate/shipPlan/createShippingPlanDetail"),
  },
  {
    path: "/unshippedListOperate", //未发货清单
    name: "UnshippedListOperate",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      keepAlive: true, //需要缓存的页面
      deepth: 1
    },
    component: () => import("@pages/operate/shipPlan/unshippedList"),
  },
  {
    path: "/pickingCartOperate", //拣货车
    name: "PickingCartOperate",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/shipPlan/pickingCart"),
  },
  {
    path: "/replenishmentListOperate", //补货清单
    name: "ReplenishmentListOperate",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/shipPlan/replenishmentList"),
  },
  {
    path: "/waitingForLoadingShipPlan", //待装车
    name: "WaitingForLoadingShipPlan",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/shipPlan/waitforLoading"),
  },


  
   // *****运营端-规则中心模块
   {
    path: "/ruleCenterList", //规则中心
    name: "RuleCenterList",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/ruleCenter"),
  },



   // *****运营端-质检报告模块
   {
    path: "/qualityReport", //质检报告
    name: "QualityReport",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/qualityReport"),
  },

   // *****运营端-订单和发货维护模块
   {
    path: "/orderAndShipping", //订单和发货维护
    name: "OrderAndShipping",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      deepth: 0.5 // 定义路由的层级
    },
    component: () => import("@pages/operate/orderAndShipping"),
  },
   // *****运营端-对账单模块
   {
    path: "/statementOfAccountIndex", //对账单
    name: "StatementOfAccountIndex",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/statementOfAccount"),
  },
   {
    path: "/statementOfAccountSupplier", //对账单-商家
    name: "StatementOfAccountSupplier",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/statementOfAccount/supplier"),
  },
   {
    path: "/statementOfAccountSupplierDetail", //对账单-商家明细
    name: "StatementOfAccountSupplierDetail",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
      deepth: 0.5 // 定义路由的层级

    },
    component: () => import("@pages/operate/statementOfAccount/supplierDetail"),
  },
   {
    path: "/statementOfAccountClient", //对账单-客户
    name: "StatementOfAccountClient",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/statementOfAccount/client"),
  },
   {
    path: "/statementOfAccountClientDetail", //对账单-客户明细
    name: "StatementOfAccountClientDetail",
    meta: {
      navBarFlag: false,
      tabBarFlag: false,
      requiredAuth: true,
    },
    component: () => import("@pages/operate/statementOfAccount/clientDetail"),
  },
];
